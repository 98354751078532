import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c1ee67b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-keyword-search" }
const _hoisted_2 = { class: "item-keyword-search-body" }
const _hoisted_3 = { class: "item-keyword-search-input shadow" }
const _hoisted_4 = { class: "item-keyword-search-footer level" }
const _hoisted_5 = {
  key: 0,
  class: "item-keyword-search-link level-right is-mobile"
}
const _hoisted_6 = {
  id: "image-search-button",
  class: "has-text-link level is-mobile",
  type: "button",
  "data-cy": "image-search-button",
  tabindex: "-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlaceList = _resolveComponent("PlaceList")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_KeywordSearch = _resolveComponent("KeywordSearch")!
  const _component_ExampleQueries = _resolveComponent("ExampleQueries")!
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PlaceList, {
        modelValue: _ctx.checkedPlaces,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedPlaces) = $event)),
        places: _ctx.places
      }, null, 8, ["modelValue", "places"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_KeywordSearch, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
          onSearch: _ctx.search,
          onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setKeyword($event))),
          placeholder: _ctx.$t('label.keyword'),
          styled: "is-none",
          class: "item-keyword-search-form",
          label: _ctx.$t('label.keyword')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ButtonIcon, {
              id: "item-keyword-search-button",
              "aria-label": _ctx.$t('parts.search'),
              styled: "is-accent",
              type: "submit",
              text: _ctx.$t('parts.search')
            }, null, 8, ["aria-label", "text"])
          ]),
          _: 1
        }, 8, ["modelValue", "onSearch", "placeholder", "label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ExampleQueries),
      (_ctx.$route.name === 'TopPage')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, { to: { name: 'SearchImage' } }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_6, [
                  _createVNode(_component_DssIcon, { icon: "image" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('label.imageSearch')), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_DssIcon, {
              icon: "separater",
              class: "has-text-thin"
            }),
            _createVNode(_component_router_link, { to: { name: 'DetailedSearch' } }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toDetailSearch && _ctx.toDetailSearch(...args))),
                  onKeydown: _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.toDetailSearch && _ctx.toDetailSearch(...args)), ["prevent"]), ["space","enter"])),
                  id: "detail-search-button",
                  class: "has-text-link level",
                  type: "button",
                  "data-cy": "datail-search-button",
                  tabindex: "-1"
                }, _toDisplayString(_ctx.$t("label.advancedSearch")), 33)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}