
import { useI18n } from 'vue-i18n'
import { defineComponent, computed } from 'vue'
import AppImg from '@/components/atoms/AppImg.vue'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import AppLabelPlaces from '@/components/atoms/AppLabelPlaces.vue'
import PlaceholderImage from '@/components/atoms/PlaceholderImage.vue'
import { useRoute } from 'vue-router'
import { getPermissionColor, ruleToType } from '@/data/Permission'

export default defineComponent({
  name: 'ItemCardSpotlightView',
  components: {
    AppImg,
    AppIconPlaces,
    AppLabelPlaces,
    PlaceholderImage,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
    title: {
      type: Object,
      required: true,
    },
    thumbnail: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  setup (props) {
    const CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)

    const permissionRule = computed(() => props.item.permission?.rule ? props.item.permission.rule : 'internet')
    const permissionType = computed(() => ruleToType(permissionRule.value))
    const permissionColor = computed(() => {
      return getPermissionColor(permissionType.value, isRekion.value)
    })
    const permissionText = computed(() => {
      const type = props.item.permission?.type
      if (type !== 'internet' && type !== 'ooc' && type !== 'inlibrary') {
        return ''
      }
      return isRekion.value ? i18n.t(`rekionAccessRestriction.${type}`) : i18n.t(`accessRestriction.${type}`)
    })
    const spotlightItemTitle = computed(() => props.title?.[lang.value] ?? props.item?.meta?.['0001Dtct']?.[0])
    const spotlightItemThumbnail = computed(() => props.thumbnail ? props.thumbnail : CONTENTS_BASE_URL + props.item.thumbnail)
    const spotlightItemLabel = computed(() => props.label?.[lang.value] ?? permissionText.value)

    return {
      CONTENTS_BASE_URL,
      lang,
      spotlightItemTitle,
      spotlightItemThumbnail,
      spotlightItemLabel,
      permissionType,
      permissionColor,
    }
  },
})
