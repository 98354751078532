
import { useI18n } from 'vue-i18n'
import { defineComponent } from 'vue'
import ItemKeywordSearch from '@/components/organisms/ItemKeywordSearch.vue'
import TheSpotlight from '@/components/organisms/TheSpotlight.vue'
import CollectionList from '@/components/organisms/CollectionList.vue'
import NewsList from '@/components/organisms/NewsList.vue'
import TheSiteDescription from '@/components/organisms/rekion/TheSiteDescription.vue'
import TheOnlineServices from '@/components/organisms/TheOnlineServices.vue'
import TheSiteGuidance from '@/components/organisms/rekion/TheSiteGuidance.vue'

export default defineComponent({
  components: {
    ItemKeywordSearch,
    TheSpotlight,
    CollectionList,
    NewsList,
    TheSiteGuidance,
    TheSiteDescription,
    TheOnlineServices,
  },
  props: {
    genres: {
      type: Array,
      default: () => [],
    },
    exampleQueries: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const i18n = useI18n()
    const places = [
      { value: 'internet', label: i18n.t('rekionMainSearch.rekionAccessRestriction.internet') },
      { value: 'inlibrary', label: i18n.t('rekionMainSearch.rekionAccessRestriction.inlibrary') },
    ]

    return {
      places,
    }
  },
})
