
import { defineComponent } from '@vue/runtime-core'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheOnlineServices',
  components: {
    DssIcon,
  },
  setup () {
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)
    const rekionUrl = process.env.VUE_APP_REKION_APPLICATION_LINK
    const ddUrl = process.env.VUE_APP_DD_APPLICATION_LINK
    const i18n = useI18n()
    const lang = i18n.locale
    return {
      isRekion,
      ddUrl,
      rekionUrl,
      lang,
    }
  },
})
