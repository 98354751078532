import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-480641f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "example-queries" }
const _hoisted_2 = {
  key: 0,
  class: "has-text-modest level-left is-mobile flex-wrap",
  "data-cy": "example-queries"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.randomQueries)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('label.example')) + "... ", 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.randomQueries, (keyword, i) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              onClick: _ctx.searchKeyword,
              onKeydown: _withKeys(_withModifiers(_ctx.searchKeyword, ["prevent"]), ["space","enter"]),
              key: i,
              to: { name: _ctx.toRoute, query: { keyword: keyword, accessRestrictions: _ctx.checkedPlaces, pageSize: _ctx.pageSize, sortKey: _ctx.sortKey }}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(keyword), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "onKeydown", "to"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}