
import { defineComponent } from '@vue/runtime-core'
import TheListAbout from '@/components/organisms/rekion/TheListAbout.vue'

export default defineComponent({
  name: 'TheSiteDescription',
  components: {
    TheListAbout,
  },
})
