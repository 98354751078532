interface Master {[key: string]: {ja: string, en: string}}

const genreList: Master = {
  G1: { ja: '器楽（クラシック音楽）', en: 'Instrumental (Classical music)' },
  G2: { ja: '声楽', en: 'Vocal music' },
  G3: { ja: '民謡、国民音楽（日本）', en: 'Folksongs and national music (Japan)' },
  G4: { ja: '唱歌、童謡', en: 'Children\'s songs' },
  G5: { ja: '歌謡曲、流行歌、シャンソン、ジャズソング', en: 'Popular songs/Chanson/Jazz songs' },
  G6: { ja: '劇音楽', en: 'Theatrical music' },
  G7: { ja: '邦楽', en: 'Japanese traditional music' },
  G8: { ja: '詩吟、朗詠', en: 'Recitation of poems' },
  G9: { ja: '落語', en: 'Rakugo' },
  G10: { ja: '漫談、漫才', en: 'Mandan/Manzai' },
  G11: { ja: '浪曲', en: 'Rokyoku' },
  G12: { ja: '講談', en: 'Kodan' },
  G13: { ja: '歌舞伎', en: 'Kabuki' },
  G14: { ja: '演劇、演芸', en: 'Vaudeville' },
  G15: { ja: '講義、講演、演説', en: 'Lectures/speeches/addresses' },
  G16: { ja: '文学作品の朗読、解説', en: 'Literary readings/introductions' },
  G17: { ja: '実況記録', en: 'On-the-spot records' },
  G18: { ja: '宗教、宗教音楽', en: 'Religious materials' },
  G19: { ja: '教育・児童', en: 'Educational materials ' },
  G20: { ja: '自然音・効果音', en: 'Sound effects/sounds of nature' },
}

export default genreList
