import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d73e57fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-header" }
const _hoisted_2 = {
  key: 1,
  class: "search-result"
}
const _hoisted_3 = { class: "search-result-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionDetail = _resolveComponent("CollectionDetail")!
  const _component_LoadingView = _resolveComponent("LoadingView")!
  const _component_TheSearchResultLeftColumn = _resolveComponent("TheSearchResultLeftColumn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CollectionDetail, {
        collectionId: _ctx.selectedCollectionId,
        genre: _ctx.selectedGenre
      }, null, 8, ["collectionId", "genre"])
    ]),
    (_ctx.isProcessing)
      ? (_openBlock(), _createBlock(_component_LoadingView, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_TheSearchResultLeftColumn, {
              class: "search-result-side",
              collectionId: _ctx.selectedCollectionId
            }, null, 8, ["collectionId"]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
              pageNo: _ctx.pageNo,
              class: "search-result-main"
            }, null, 8, ["pageNo"]))
          ])
        ]))
  ], 64))
}