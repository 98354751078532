import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppLabelPlaces = _resolveComponent("AppLabelPlaces")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppLabelPlaces, {
      type: _ctx.placeIconType,
      tag: "span",
      class: "is-hidden-desktop"
    }, {
      default: _withCtx(() => [
        (_ctx.mainMessage)
          ? (_openBlock(), _createBlock(_component_AppIconPlaces, {
              key: 0,
              type: _ctx.placeIconType
            }, null, 8, ["type"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["type"]),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mainMessage), 1),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 64))
}