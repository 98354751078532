
import { filter } from 'lodash'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppTag from '@/components/atoms/AppTag.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import ItemCardView from '@/components/organisms/ItemCardSpotlightView.vue'
import { setSlideUI } from '@/helpers/util/slideUI'
import { spotlightCategoryTag, spotlightItem } from '@/data/@types/Spotlight'

export default defineComponent({
  components: {
    AppTag,
    ButtonIcon,
    ItemCardView,
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()
    const route = useRoute()
    const isRekion = route.meta.isRekion

    const scrollTarget = ref()
    const nextButton = ref()
    const prevButton = ref()

    store.dispatch('loadSpotlight', isRekion)

    const state = reactive({
      filtered: 'all',
      currentPosition: 1,
    })

    const initSlideUI = (isUpdate = false) => {
      setSlideUI(
        scrollTarget.value, // scrollTarget: HTMLElement,
        nextButton.value.$el, // nextButton: HTMLElement,
        prevButton.value.$el, // prevButton: HTMLElement,
        state, // state: { currentPosition: number },
        12, // gap: number,
        true, // touchSupport = true,
        isUpdate // update = false
      )
    }

    const getCategoryLabel = (tag: spotlightCategoryTag) => {
      return tag.label[lang.value as 'ja'|'en'] || ''
    }

    const onFilter = (id: string) => {
      state.filtered = id
      state.currentPosition = 1
      setTimeout(() => {
        initSlideUI(true)
      }, 1000)
    }

    const spotlightCategoryTags = computed(() => {
      return store.getters.getSpotlightCategoryTags
    })

    const spotlightItems = computed(() => {
      const items = store.getters.getSpotlightItems
      if (state.filtered === 'all') return items.slice(0, 9)

      const filterItem = filter(items, (v: spotlightItem) => {
        return v.tagId?.some(id => id === state.filtered)
      })

      return filterItem.slice(0, 9)
    })

    const indexSize = computed(() => {
      return Math.ceil(spotlightItems.value.length / 3)
    })

    const currentIndex = computed(() => {
      return Math.ceil(state.currentPosition / 3)
    })

    onMounted(() => {
      initSlideUI()
    })

    return {
      state,
      indexSize,
      isRekion,
      currentIndex,
      onFilter,
      scrollTarget,
      nextButton,
      prevButton,
      getCategoryLabel,
      lang,
      spotlightItems,
      spotlightCategoryTags,
    }
  },
})
