
import { useI18n } from 'vue-i18n'
import { defineComponent } from '@vue/runtime-core'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import AppLabelPlaces from '@/components/atoms/AppLabelPlaces.vue'
import GuidanceCell from '@/components/molecules/GuidanceCell.vue'

export default defineComponent({
  name: 'TheSiteUsageGuidance',
  components: {
    AppIconPlaces,
    AppLabelPlaces,
    GuidanceCell,
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    const rekionAccessRestrictions = [
      { value: 'internet', label: i18n.t('rekionGuidance.rekionAccessRestriction.internet') },
      { value: 'inlibrary', label: i18n.t('rekionGuidance.rekionAccessRestriction.inlibrary') },
    ]

    return {
      rekionAccessRestrictions,
      lang,
    }
  },
})
