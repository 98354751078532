
import { defineComponent, reactive, computed } from 'vue'
import AppCheckbox from '../atoms/AppCheckbox.vue'
import AppLabelPlaces from '../atoms/AppLabelPlaces.vue'
import AppIconPlaces from '../atoms/AppIconPlaces.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    AppCheckbox,
    AppLabelPlaces,
    AppIconPlaces,
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    places: {
      type: Array,
      required: true,
    },
  },
  setup (props, context) {
    const i18n = useI18n()
    const lang = i18n.locale

    const state = reactive({
      modal1: false,
    })

    const values = computed({
      get: () => props.modelValue,
      set: (val) => context.emit('update:modelValue', val),
    })

    return {
      state,
      lang,
      values,
    }
  },
})
