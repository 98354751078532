
import { defineComponent, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import DssIcon from '@/components/atoms/DssIcon.vue'

export default defineComponent({
  name: 'NewsList',
  components: {
    DssIcon,
  },
  setup () {
    const CONTENTS_BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL

    const i18n = useI18n()
    const lang = i18n.locale

    const includeHtml = ref('')

    const loadHtml = (htmlFile: string) => {
      const url = `${lang.value}/${htmlFile}`

      fetch(`${CONTENTS_BASE_URL}static/${url}`).then((res) => {
        res.text().then((html) => {
          includeHtml.value = html || ''
        })
      })
    }
    onMounted(() => {
      loadHtml('news-list.html')
    })

    return {
      includeHtml,
    }
  },
})
