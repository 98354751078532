import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "the-list-about" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/aboutRekion" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('rekionSiteDescription.about')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/rekion_librarylist" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('rekionSiteDescription.librarylist')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/rekion4Lib" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('rekionSiteDescription.library')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/rekion_faq" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('rekionSiteDescription.faq')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}