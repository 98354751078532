
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppImg from '@/components/atoms/AppImg.vue'
import RekionGenres from '@/data/master/RekionGenres'

export default defineComponent({
  namd: 'CollectionList',
  components: {
    DssIcon,
    AppImg,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    collections: {
      type: Array,
      required: true,
    },
  },
  setup () {
    const BASE_URL = process.env.VUE_APP_CONTENTS_BASE_URL
    const i18n = useI18n()
    const lang = i18n.locale
    const store = useStore()
    const isRekion = process.env.VUE_APP_IS_REKION === 'TRUE'

    const getCollectionLabel = (collectionId: string) => {
      const CollectionList = store.getters.CollectionList
      const fetchCollection = CollectionList.find((collection: any) => collection.collection === collectionId)
      return fetchCollection.label
    }

    const getCollectionThumbnail = (collectionId: string) => {
      const collectionMaster = store.getters.CollectionMaster
      return BASE_URL + collectionMaster[collectionId].content.thumbnail
    }

    const getGenreInfo = (genreId: string) => {
      return {
        label: RekionGenres[genreId][lang.value as 'ja' | 'en'],
        query: RekionGenres[genreId].ja, // クエリには日本語を渡す
      }
    }

    return {
      isRekion,
      placeholderType: store.getters.PlaceholderImageType,
      isMasterFetched: computed(() => store.getters.IsMasterFetched),
      lang,
      more: ref(false),
      getCollectionLabel,
      getCollectionThumbnail,
      getGenreInfo,
    }
  },
})
