
import { computed, defineComponent } from 'vue'
import TheSearchResultMain from '@/components/organisms/TheSearchResultMain.vue'
import TheSearchImageResultMain from '@/components/organisms/TheSearchImageResultMain.vue'
import TheSearchResultLeftColumn from '@/components/organisms/TheSearchResultLeftColumn.vue'
import CollectionDetail from '@/components/organisms/CollectionDetail.vue'
import LoadingView from '@/components/molecules/LoadingView.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    TheSearchResultMain,
    TheSearchImageResultMain,
    TheSearchResultLeftColumn,
    CollectionDetail,
    LoadingView,
  },
  props: {
    pageNo: {
      type: String,
      default: '1',
    },
    component: {
      type: String,
      required: true,
    },
  },
  setup () {
    const route = useRoute()
    const selectedCollectionId = 'A00024'
    const selectedGenre = route.query.genre
    const store = useStore()
    const isProcessing = computed(() => store.getters.itemIsProcessing)
    return {
      selectedCollectionId,
      selectedGenre,
      isProcessing,
    }
  },
})
