
import { defineComponent } from 'vue'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import AppLabelPlaces from '@/components/atoms/AppLabelPlaces.vue'

export default defineComponent({
  name: 'UsageGuidanceTableCell',
  components: {
    AppIconPlaces,
    AppLabelPlaces,
  },
  props: {
    mainMessage: {
      type: String,
      require: false,
    },
    placeIconType: {
      type: String,
      require: true,
    },
  },
})
