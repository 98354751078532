
import { defineComponent } from 'vue'
import RekionTopPage from '@/components/templates/RekionTopPage.vue'

export default defineComponent({
  components: {
    RekionTopPage,
  },
  setup () {
    // TOP画面に表示するジャンル
    const genres = [
      'G1',
      'G2',
      'G3',
      'G4',
      'G5',
      'G6',
      'G7',
      'G8',
      'G9',
      'G10',
      'G11',
      'G12',
      'G13',
      'G14',
      'G15',
      'G16',
      'G17',
      'G18',
      'G19',
      'G20',
    ]

    return {
      genres,
    }
  },
})
