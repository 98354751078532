
import { ExampleQueries } from '@/data/@types/ExampleQueries'
import { store } from '@/store'
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)
    const exampleQueries = computed<ExampleQueries>(() => store.getters.getExampleQueries)
    if (!exampleQueries.value[lang.value as 'ja' | 'en']?.length) store.dispatch('loadExampleQueries')
    const randomQueries = computed(() => {
      if (!exampleQueries.value[lang.value as 'ja' | 'en']?.length) return []
      const queries = [...exampleQueries.value[lang.value as 'ja' | 'en']]
      const displayLength = exampleQueries.value.length || 5
      return [...Array(displayLength)].map(() => queries.splice(Math.floor(Math.random() * queries.length), 1)[0])
    })
    const checkedPlaces = computed(() =>
      isRekion.value ? store.getters.getRekionAccessRestrictionsCheckList : store.getters.getAccessRestrictionsCheckList
    )

    const searchMode = computed(() => store.getters.searchMode)
    const sortKey = computed(() => route.query.sortKey ? route.query.sortKey : searchMode.value ? searchMode.value.sortKey : 'SCORE')
    const pageSize = computed(() => route.query.pageSize ? Number(route.query.pageSize.toString()) : searchMode.value ? Number(searchMode.value.pageSize) : 20)

    const toRoute = isRekion.value ? 'RekionSearchResult' : 'DetailedSearchResult'

    const searchKeyword = () => {
      store.commit('SET_OPEN_CLOSURE', false)
    }

    return {
      lang,
      checkedPlaces,
      searchKeyword,
      randomQueries,
      toRoute,
      sortKey,
      pageSize,
    }
  },
})
