import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f611d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-online-services has-background-contents shadow" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "the-online-services-body" }
const _hoisted_4 = {
  key: 0,
  class: "the-online-services-item"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "the-online-services-item-banner" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "flex-grow has-text-modest" }
const _hoisted_9 = {
  key: 1,
  class: "the-online-services-item"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "the-online-services-item-banner" }
const _hoisted_12 = ["src", "alt"]
const _hoisted_13 = { class: "flex-grow has-text-modest" }
const _hoisted_14 = { class: "the-online-services-item" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "the-online-services-item-banner" }
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = { class: "flex-grow has-text-modest" }
const _hoisted_19 = { class: "the-online-services-item" }
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "the-online-services-item-banner" }
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = { class: "flex-grow has-text-modest" }
const _hoisted_24 = { class: "the-online-services-item" }
const _hoisted_25 = {
  href: "https://warp.da.ndl.go.jp/",
  target: "_blank",
  rel: "noopener"
}
const _hoisted_26 = { class: "the-online-services-item-banner" }
const _hoisted_27 = ["src", "alt"]
const _hoisted_28 = { class: "flex-grow has-text-modest" }
const _hoisted_29 = { class: "the-online-services-item" }
const _hoisted_30 = {
  href: "https://id.ndl.go.jp/auth/ndla",
  target: "_blank",
  rel: "noopener"
}
const _hoisted_31 = { class: "the-online-services-item-banner" }
const _hoisted_32 = ["src", "alt"]
const _hoisted_33 = { class: "flex-grow has-text-modest" }
const _hoisted_34 = { class: "the-online-services-footer level-right is-mobile" }
const _hoisted_35 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('serviceList.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", null, [
        (_ctx.isRekion)
          ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
              _createElementVNode("a", {
                href: `${_ctx.ddUrl}/${_ctx.lang}/`,
                target: "_blank",
                rel: "noopener"
              }, [
                _createElementVNode("span", _hoisted_6, [
                  _createElementVNode("img", {
                    src: '/img/banner' + _ctx.$t('img.banner.ndldd'),
                    alt: _ctx.$t('serviceList.ndlDd.title')
                  }, null, 8, _hoisted_7)
                ]),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('serviceList.ndlDd.description')), 1)
              ], 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("li", _hoisted_9, [
              _createElementVNode("a", {
                href: `${_ctx.rekionUrl}/${_ctx.lang}/`,
                target: "_blank",
                rel: "noopener"
              }, [
                _createElementVNode("span", _hoisted_11, [
                  _createElementVNode("img", {
                    src: '/img/banner' + _ctx.$t('img.banner.ndlrekion'),
                    alt: _ctx.$t('serviceList.ndlRekion.title')
                  }, null, 8, _hoisted_12)
                ]),
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('serviceList.ndlRekion.description')), 1)
              ], 8, _hoisted_10)
            ])),
        _createElementVNode("li", _hoisted_14, [
          _createElementVNode("a", {
            href: _ctx.$t('serviceList.ndlSearch.link'),
            target: "_blank",
            rel: "noopener"
          }, [
            _createElementVNode("span", _hoisted_16, [
              _createElementVNode("img", {
                class: "fill-background-banner",
                src: '/img/banner' + _ctx.$t('img.banner.ndlSearch'),
                alt: _ctx.$t('serviceList.ndlSearch.title')
              }, null, 8, _hoisted_17)
            ]),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('serviceList.ndlSearch.description')), 1)
          ], 8, _hoisted_15)
        ]),
        _createElementVNode("li", _hoisted_19, [
          _createElementVNode("a", {
            href: _ctx.$t('serviceList.ndlNavi.link'),
            target: "_blank",
            rel: "noopener"
          }, [
            _createElementVNode("span", _hoisted_21, [
              _createElementVNode("img", {
                class: "fill-background-banner",
                src: '/img/banner' + _ctx.$t('img.banner.rnavi'),
                alt: _ctx.$t('serviceList.ndlNavi.title')
              }, null, 8, _hoisted_22)
            ]),
            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('serviceList.ndlNavi.description')), 1)
          ], 8, _hoisted_20)
        ]),
        _createElementVNode("li", _hoisted_24, [
          _createElementVNode("a", _hoisted_25, [
            _createElementVNode("span", _hoisted_26, [
              _createElementVNode("img", {
                src: '/img/banner' + _ctx.$t('img.banner.warp'),
                alt: _ctx.$t('serviceList.ndlWarp.title')
              }, null, 8, _hoisted_27)
            ]),
            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t('serviceList.ndlWarp.description')), 1)
          ])
        ]),
        _createElementVNode("li", _hoisted_29, [
          _createElementVNode("a", _hoisted_30, [
            _createElementVNode("span", _hoisted_31, [
              _createElementVNode("img", {
                src: '/img/banner' + _ctx.$t('img.banner.ndla'),
                alt: _ctx.$t('serviceList.ndlAuthorities.title')
              }, null, 8, _hoisted_32)
            ]),
            _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('serviceList.ndlAuthorities.description')), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_34, [
      _createElementVNode("a", {
        href: _ctx.$t('link.ndlWebService'),
        rel: "noopener",
        target: "_blank",
        class: "level is-mobile"
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('serviceList.checkAllService')), 1),
        _createVNode(_component_DssIcon, { icon: "arrow-right" })
      ], 8, _hoisted_35)
    ])
  ]))
}