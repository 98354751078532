
import { computed, defineComponent, ref, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import KeywordSearch from '@/components/molecules/KeywordSearch.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { useStore } from 'vuex'
import ExampleQueries from '@/components/organisms/ExampleQueries.vue'
import PlaceList from '@/components/organisms/PlaceList.vue'
import StringKeyObject from '@/data/@types/StringKeyObject'
import { defaultQuery } from '@/domain/item/SearchRequest'

export default defineComponent({
  components: {
    KeywordSearch,
    ButtonIcon,
    DssIcon,
    ExampleQueries,
    PlaceList,
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    places: {
      type: Array,
      required: false,
    },
  },
  setup (props) {
    const value = computed(() => props.keyword)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const isRekion = computed(() => route.meta.isRekion)
    const searchMode = computed(() => store.getters.searchMode)
    const setKeyword = (event: any) => {
      store.commit('SET_INPUT_KEYWORD', event.target.value)
    }

    const checkedPlaces = ref(
      isRekion.value ? store.getters.getRekionAccessRestrictionsCheckList : store.getters.getAccessRestrictionsCheckList
    )

    const search = async (keyword: string) => {
      if (isRekion.value) {
        store.commit('SET_REKION_ACCESS_RESTRICTIONS', checkedPlaces.value)
      } else {
        store.commit('SET_ACCESS_RESTRICTIONS', checkedPlaces.value)
      }
      const trimmedKeyword = keyword.trim()

      store.commit('SET_OPEN_CLOSURE', false)

      const useQuery: StringKeyObject = { ...defaultQuery }
      useQuery.fullText = !isRekion.value
      if (trimmedKeyword) useQuery.keyword = trimmedKeyword
      if (searchMode.value.pageSize) useQuery.pageSize = searchMode.value.pageSize
      if (searchMode.value.sortKey) useQuery.sortKey = searchMode.value.sortKey
      if (searchMode.value.displayMode) useQuery.displayMode = searchMode.value.displayMode
      if (checkedPlaces.value.length) useQuery.accessRestrictions = checkedPlaces.value

      const name = isRekion.value ? 'RekionSearchResult' : 'DetailedSearchResult'
      store.commit('CACHE_QUERY', {})
      router.push({
        name,
        query: useQuery,
      })
    }

    watch(checkedPlaces, () => {
      isRekion.value ? store.commit('SET_REKION_ACCESS_RESTRICTIONS', checkedPlaces.value) : store.commit('SET_ACCESS_RESTRICTIONS', checkedPlaces.value)
    })

    const defaultPermissionCheck = localStorage.getItem('defaultPermissionCheck') ? localStorage.getItem('defaultPermissionCheck') : 'true'

    onMounted(() => {
      if (!isRekion.value && defaultPermissionCheck === 'true') {
        checkedPlaces.value = ['internet', 'ooc', 'inlibrary']
        localStorage.setItem('defaultPermissionCheck', 'false')
      }
    })

    const toDetailSearch = () => {
      store.commit('SET_OPEN_CLOSURE', true)
    }

    return {
      value,
      search,
      setKeyword,
      checkedPlaces,
      store,
      toDetailSearch,
      isRekion,
    }
  },
})
