import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bd057fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "collection-list"
}
const _hoisted_2 = { class: "level is-mobile" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "collection-grid" }
const _hoisted_5 = {
  key: 0,
  class: "is-hidden-mobile"
}
const _hoisted_6 = { class: "collection-heading title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppImg = _resolveComponent("AppImg")!

  return (_ctx.isRekion || _ctx.isMasterFetched)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.heading), 1),
          (!_ctx.isRekion)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: '/collection',
                id: "to-collection-page",
                class: "level is-mobile"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('label.allCollections')) + " ", 1),
                  _createVNode(_component_DssIcon, { icon: "arrow-right" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collections, (collection) => {
            return (_openBlock(), _createElementBlock("li", {
              key: collection,
              class: "collection has-background-contents shadow"
            }, [
              _createVNode(_component_router_link, {
                to: _ctx.isRekion ? { name: 'RekionSearchResult', query: { musicType: _ctx.getGenreInfo(collection).query } } : `/collections/${collection}?pageNum=0`,
                class: "collection-inner has-text-normal",
                "data-cy": collection
              }, {
                default: _withCtx(() => [
                  (!_ctx.isRekion)
                    ? (_openBlock(), _createElementBlock("figure", _hoisted_5, [
                        _createVNode(_component_AppImg, {
                          src: _ctx.getCollectionThumbnail(collection),
                          alt: ""
                        }, null, 8, ["src"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.isRekion ? _ctx.getGenreInfo(collection).label : _ctx.getCollectionLabel(collection)[_ctx.lang]), 1)
                ]),
                _: 2
              }, 1032, ["to", "data-cy"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}