import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-98c0ddc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-site-guidance has-background-contents shadow" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "the-site-guidance-body" }
const _hoisted_4 = { class: "the-site-guidance-table" }
const _hoisted_5 = { class: "the-site-guidance-table-title" }
const _hoisted_6 = { class: "has-background-page" }
const _hoisted_7 = { class: "has-background-page" }
const _hoisted_8 = { class: "has-background-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppLabelPlaces = _resolveComponent("AppLabelPlaces")!
  const _component_GuidanceCell = _resolveComponent("GuidanceCell")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('rekionGuidance.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('rekionGuidance.description')), 1),
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", _hoisted_5, [
            _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('rekionGuidance.thType.head')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rekionAccessRestrictions, (accessRestriction, index) => {
              return (_openBlock(), _createElementBlock("td", { key: index }, [
                _createVNode(_component_AppLabelPlaces, {
                  type: accessRestriction.value !== 'inlibrary' ? accessRestriction.value : 'rekion',
                  tag: "div",
                  fill: true,
                  class: "the-site-guidance-place-label"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppIconPlaces, {
                      type: accessRestriction.value
                    }, null, 8, ["type"]),
                    _createTextVNode(" " + _toDisplayString(accessRestriction.label), 1)
                  ]),
                  _: 2
                }, 1032, ["type"])
              ]))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('rekionGuidance.thResource.head', { newLine: '\r\n' })), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('rekionGuidance.thResource.internet.text'),
                "place-icon-type": "internet"
              }, null, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('rekionGuidance.thResource.inlibrary.text'),
                "place-icon-type": "rekion"
              }, null, 8, ["main-message"])
            ])
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('rekionGuidance.thMethod.head', { newLine: '\r\n' })), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('rekionGuidance.thMethod.internet.text'),
                "place-icon-type": "internet"
              }, null, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('rekionGuidance.thMethod.inlibrary.text'),
                "place-icon-type": "rekion"
              }, null, 8, ["main-message"])
            ])
          ])
        ])
      ])
    ])
  ]))
}